import Axios from 'axios';
Axios.defaults.baseURL = 'https://api.sevvenpost.ru/sevven/hs/lkapi/v1/';
Axios.defaults.withCredentials = true;

export default {
  throwAPIError(error) {
    console.log('ERROR: ');
    console.log(error);
    if (error.data.error && error.data.error.code == '501') {
      localStorage.clear();
      throw new Error(error.data.error.message);
    } else if (error.data.error && error.data.error.message)
      throw new Error(error.data.error.message);
    else throw new Error('Ошибка запроса к серверу');
  },
  async get(request) {
    const response = await Axios.get(request);
    if (response.status != '200' || !response.data.success) {
      this.throwAPIError(response);
    }
    return response.data.data;
  },
  async getBlob(request) {
    const response = await Axios.get(request, {
      responseType: 'blob'
    });
    if (response.status != '200') {
      this.throwAPIError(response);
    }
    return response.data;
  },
  async getNative(request) {
    const response = await Axios.get(request);
    return response.data;
  },
  async post(request, data) {
    let response = await Axios.post(request, data);
    if (response.status != '200' || !response.data.success) {
      this.throwAPIError(response);
    }
    return response.data.data;
  },
  async patch(request, data) {
    let response = await Axios.patch(request, data);
    if (response.status != '200' || !response.data.success) {
      this.throwAPIError(response);
    }
    return response.data.data;
  },
  async delete(request) {
    let response = await Axios.delete(request);
    if (response.status != '200' || !response.data.success) {
      this.throwAPIError(response);
    }
    return response.data.data;
  },
};
