import ordersAPI from '@/api/orders.js';

export default {
  state: {
    order: {
      id: '',
      number: '',
      urgency: 'Обычная',
      date: '',
      contact: {
        id: '',
        name: '',
      },
      counterparty: {
        id: '',
        name: '',
      },
      sender: {
        id: '',
        name: '',
        type: '',
      },
      sender_contact: {
        id: '',
        name: '',
      },
      sender_address: {
        value: '',
        oktmo: '',
        city: ' ',
        country: '',
      },
      receiver: {
        id: '',
        name: '',
        type: '',
      },
      receiver_contact: {
        id: '',
        name: '',
      },
      receiver_address: {
        value: '',
        oktmo: '',
        city: '',
        country: '',
      },
      payer: {
        id: '',
        name: '',
        type: '',
      },
      status: {
        id: '',
        value: '',
      },
      summa: 0,
      itemscount: 0,
      items: [],
      paymentType: {
        id: 'Безналичный',
        value: 'Безналичный',
      },
    },
    ordersToCreate: [],
    orderList: [],
    currentHistory: [],
  },

  mutations: {
    setOrder(state, data) {
      state.order = data;
    },
    resetOrder(state) {
      state.order = {
        id: '',
        number: '',
        urgency: 'Обычная',
        date: '',
        contact: {
          id: '',
          name: '',
        },
        counterparty: {
          id: '',
          name: '',
        },
        sender: {
          id: '',
          name: '',
          type: '',
        },
        sender_contact: {
          id: '',
          name: '',
        },
        sender_address: {
          value: '',
          oktmo: '',
          city: '',
          country: '',
        },
        receiver: {
          id: '',
          name: '',
          type: '',
        },
        receiver_contact: {
          id: '',
          name: '',
        },
        receiver_address: {
          value: '',
          oktmo: '',
          city: '',
          country: '',
        },
        payer: {
          id: '',
          name: '',
          type: '',
        },
        status: {
          id: '',
          value: '',
        },
        summa: 0,
        itemscount: 0,
        items: [],
        paymentType: {
          id: 'Безналичный',
          value: 'Безналичный',
        },
      };
    },
    setOrders(state, data) {
      const {
        items
      } = data;
      state.orderList = items;
    },
    setVersionHistory(state, data) {
      state.currentHistory = data;
    },
    setOrderToCreate(state, data) {
      state.ordersToCreate = data;
    },
    resetOrdersToCreate(state) {
      state.ordersToCreate = [{
        receiver: {
          id: '',
          name: '',
          type: '',
        },
        receiver_contact: {
          id: '',
          name: '',
        },
        receiver_address: {
          value: '',
          oktmo: '',
          city: '',
          country: '',
        },
        payer: {
          id: '',
          name: '',
          type: '',
        },
        status: {
          id: '',
          value: '',
        },
        summa: 0,
        itemscount: 0,
        items: [],
        urgency: 'Обычная',
        paymentType: {
          id: 'Безналичный',
          value: 'Безналичный',
        },
        showAddresses: false,
        currentPayer: '',
      }, ];
    }
  },
  getters: {
    currentOrder(state) {
      return state.order;
    },
    currentOrderList(state) {
      return state.orderList;
    },
    currentOrderHistory(state) {
      return state.currentHistory;
    },
  },
  actions: {
    async getOrder(context, id) {
      const response = await ordersAPI.loadOrder(id);
      context.commit('setOrder', response);
      return response;
    },
    async loadOrders(context) {
      const items = await ordersAPI.loadOrders();
      context.commit('setOrders', items);
      return items;
    },
    async createOrder(context) {
      const response = await ordersAPI.createOrder(context.state.order);
      return response;
    },
    async createOrders(context) {
      const responseArray = [];
      for (const order of context.state.ordersToCreate) {
        const response = await ordersAPI.createOrder(order);
        responseArray.push(response);
      }
      return responseArray;
    },
    async deleteOrder(context, id) {
      const response = await ordersAPI.deleteOrder(id);
      context.commit('resetOrder');
      return response;
    },
    async patchOrder(context, {
      id,
      data
    }) {
      const response = await ordersAPI.patchOrder(id, data);
      return response;
    },
    async getOrderInfo(context, id) {
      const response = await ordersAPI.getOrderInfo(id);
      return response.history;
    },
    async getOrderHistory(context, id) {
      const response = await ordersAPI.getOrderHistory(id);
      context.commit('setVersionHistory', response.versionHistory);
      return response.versionHistory;
    },
  },
};