import API from '@/api/api.js';

export default {
  async getClients() {
    const response = await API.get('clients/list');
    return response;
  },
  async getClient(id) {
    const response = await API.get(`clients/${id}`);
    return response;
  },
  async createClient(data) {
    const response = await API.post('clients/create', data);
    return response;
  },
  async updateClient(id, data) {
    const response = await API.patch(`clients/${id}`, data);
    return response;
  },

  async deleteClient(id) {
    const response = await API.delete(`clients/${id}`);
    return response;
  },
};
