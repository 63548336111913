import Vue from 'vue';
import Vuex from 'vuex';
import clients from '@/store/clients';
import contacts from '@/store/contacts';
import orders from '@/store/orders';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    clients,
    contacts,
    orders,
  },
  state: {
    alertIsShown: false,
    alertMessage: '',
    loaderIsShown: false,
    user: {},
  },
  mutations: {
    setAlert(state, alertMessage) {
      state.alertIsShown = true;
      state.alertMessage = alertMessage;
    },
    deleteAlert(state) {
      state.alertIsShown = false;
      state.alertMessage = '';
    },
    setLoader(state) {
      state.loaderIsShown = true;
    },
    deleteLoader(state) {
      state.loaderIsShown = false;
    },
    setUser(state, value) {
      state.user = value;
    },
  },
  getters: {
    getAlert(state) {
      return {
        alertIsShown: state.alertIsShown,
        alertMessage: state.alertMessage,
      };
    },
    getLoader(state) {
      return state.loaderIsShown;
    },
  },
});
