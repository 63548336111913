import API from '@/api/api.js';
export default {
  async loadOrder(id) {
    let response = await API.get('orders/' + id);
    return response;
  },
  async loadOrders() {
    let response = await API.get('orders/list');
    return response;
  },
  async createOrder(data) {
    let response = await API.post('orders/create', data);
    return response;
  },
  async deleteOrder(id) {
    let response = await API.delete('orders/' + id);
    return response;
  },
  async patchOrder(id, data) {
    let response = await API.patch('orders/' + id, data);
    return response;
  },
  async getOrderInfo(id) {
    let response = await API.get(`history/${id}`);
    return response;
  },
  async getOrderHistory(id) {
    let response = await API.get(`versionhistory/${id}`);
    return response;
  },
};
