import Vue from 'vue';
import VueRouter from 'vue-router';
import Auth from '@/api/auth.js';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    meta: { layout: 'MainLayout' },
    component: () => import('../views/Main'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'LoginLayout' },
    component: () => import('../views/Login'),
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: { layout: 'MainLayout' },
    component: () => import('../views/Orders'),
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: { layout: 'MainLayout' },
    component: () => import('../views/Documents'),
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: { layout: 'MainLayout' },
    component: () => import('../views/Reports'),
  },
  {
    path: '/calculator',
    name: 'Сalculator',
    meta: { layout: 'MainLayout' },
    component: () => import('../views/Calculator'),
  },
  {
    path: '/external-calculator',
    name: 'ExternalCalculator',
    meta: { layout: 'EmptyLayout' },
    component: () => import('../views/Calculator'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});
router.beforeEach(async (to, from, next) => {
  let authorized = await Auth.checkAutorization();
  if (
    to.name !== 'Login' &&
    to.name !== 'Forgot' &&
    to.name !== 'Restore' &&
    to.name !== 'ExternalCalculator' &&
    !authorized
  ) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
