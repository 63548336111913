<template>
  <v-app>
    <div class="alert">
      <v-alert
        dismissible
        type="error"
        :value="$store.getters.getAlert.alertIsShown"
      >
        {{ $store.getters.getAlert.alertMessage }}
      </v-alert>
    </div>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
export default {
  components: {
    MainLayout,
    LoginLayout,
    EmptyLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
};
</script>
<style>
.alert {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999999;
}
</style>
