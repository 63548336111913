import clientsAPI from '@/api/clients.js';

export default {
  state: {
    clients: [],
    currentClient: {},
    sender: {},
    receiver: {},
    payer: {},
  },

  mutations: {
    setClients(state, data) {
      state.clients = data.items;
    },
    setCurrentClient(state, data) {
      state.currentClient = data;
    },
    setSender(state, data) {
      state.sender = data;
    },
    setReceiver(state, data) {
      state.receiver = data;
    },
    setPayer(state, data) {
      state.payer = data;
    },
  },
  getters: {
    currentClients(state) {
      return state.clients;
    },
    currentClient(state) {
      return state.currentClient;
    },
    currentPayer(state) {
      return state.payer;
    },
    currentSender(state) {
      return state.sender;
    },
    currentReceiver(state) {
      return state.receiver;
    },
  },
  actions: {
    async uploadClients(context) {
      const clients = await clientsAPI.getClients();
      context.commit('setClients', clients);
    },
    async getClient(context, id) {
      const client = await clientsAPI.getClient(id);
      context.commit('setCurrentClient', client);
    },
    async createClient(context, data) {
      await clientsAPI.createClient(data);
    },
    async patchClient(context, { id, data }) {
      await clientsAPI.updateClient(id, data);
    },
    async deleteClient(context, id) {
      await clientsAPI.deleteClient(id);
    },
  },
};
