import clientsAPI from '@/api/contacts.js';

export default {
  state: {},

  mutations: {},
  getters: {},
  actions: {
    async createContact(context, data) {
      await clientsAPI.createContact(data);
    },
    async deleteContact(context, id) {
      await clientsAPI.deleteContact(id);
    },
    async patchContact(context, { id, data }) {
      await clientsAPI.patchContact(id, data);
    },
  },
};
