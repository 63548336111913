import API from '@/api/api.js';

export default {
  async createContact(data) {
    const response = await API.post('contacts/create', data);
    return response;
  },
  async patchContact(id, data) {
    const response = await API.patch(`contacts/${id}`, data);
    return response;
  },
  async deleteContact(id) {
    const response = await API.delete('contacts/' + id);
    return response;
  },
};
