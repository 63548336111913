import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import '@mdi/font/css/materialdesignicons.css';
import { VTextField } from 'vuetify/lib';
import '@/plugins/vuetify-mask.js';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';

Vue.config.productionTip = false;
Vue.use(VueLodash, { name: 'ld' , lodash: lodash });

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.component('VTextFieldExt', {
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    'persistent-placeholder': {
      type: Boolean,
      default: true,
    },
  },
});
Vue.mixin({
  methods: {
    showAlert(error) {
      const formattedError = error;

      if (formattedError.message.startsWith('Техническая ошибка на сервере.')) {
        formattedError.message = formattedError.message.replace(/\{.*?}/g, '');
      }

      this.$store.commit('setAlert', formattedError.message);
      setTimeout(() => {
        this.$store.commit('deleteAlert');
      }, 10000);
    },
  },
});
