import API from '@/api/api.js';
import Axios from 'axios';
import { sha256 } from 'js-sha256';
import store from '@/store';

export default {
  authorized: false,
  session: null,
  user: null,

  setSession(session) {
    this.session = session;
    localStorage.setItem('session', session);
    Axios.defaults.headers = {
      common: {
        'x-session': session,
      },
    };
  },
  async checkAutorization() {
    if (this.authorized) {
      return true;
    }
    let session = localStorage.getItem('session');
    if (session) {
      this.setSession(session);
      await this.readUserInformation();
    }
    return this.authorized;
  },
  async login(username, password) {
    const authStep1Response = await API.get(`auth/${username}`);
    this.setSession(authStep1Response.session);
    const authStep2Request = {
      hash: sha256(
        sha256(password).toUpperCase() + authStep1Response.control_string
      ).toUpperCase(),
    };
    await API.post(`auth/${username}`, authStep2Request);
    this.readUserInformation();

    this.authorized = true;
  },
  async readUserInformation() {
    try {
      let response = await API.get('self');
      this.user = response.self;
      store.commit('setUser', this.user);
      this.authorized = true;
    } catch {
      this.authorized = false;
      this.setSession(null);
    }
  },
  async logout() {
    await API.get('logout');
    this.setSession(null);
    this.authorized = false;
    this.user = null;
    this.partner = null;
  },
  hasRole(role) {
    if (!this.user || !this.user.perms) {
      return false;
    }
    return this.user.perms.includes(role);
  },
};
