<template>
  <v-app light>
    <v-main class="grey lighten-3">
      <v-container>
        <div class="home">
          <slot />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Login',
};
</script>
