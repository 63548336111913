<template>
  <section>
    <v-overlay :value="$store.getters.getLoader">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-app-bar app class="grey lighten-3" icons-and-text>
      <v-tabs centered>
        <v-tab to="orders"> Накладные </v-tab>
        <v-tab to="reports"> Отслеживание грузов </v-tab>
        <v-tab to="documents"> Счета и акты </v-tab>
        <v-tab to="calculator"> Калькулятор </v-tab>
      </v-tabs>
      <v-btn color="primary" @click="logout"> Выйти </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <slot />
      </v-container>
    </v-main>
  </section>
</template>

<script>
import Auth from '@/api/auth.js';

export default {
  name: 'Main',
  components: {},
  methods: {
    logout() {
      Auth.logout();
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
